import './rive-animation.scss';
import * as rive from '@rive-app/canvas';

class RiveAnimation {
    constructor($element) {
        this.$canvas = $element;
        this.$animationDesktop = this.$canvas.dataset.animationDesktop;
        this.$animationMobile = this.$canvas.dataset.animationMobile;
        this.$breakpoint = this.$canvas.dataset.breakpoint;
        this.$lazyload = !!this.$canvas.dataset.lazyload;
        this.$isDesktopAnimation = null;
        this.$rive = null;

        this.initialize();
    }

    initialize() {
        this.$isDesktopAnimation = window.innerWidth >= this.$breakpoint;

        const path = this.getAnimationFilePath();
        if (!path) return;

        this.cleanUp();

        if (this.$lazyload) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        if (!this.$rive) {
                            this.loadAnimation();
                            // console.log('Load Animation', this.$canvas);
                        }
                    } else {
                        if (this.$rive) {
                            this.cleanUp();
                            // console.log('CleanUp Animation', this.$rive);
                        }
                    }
                });
            });

            observer.observe(this.$canvas);
        } else {
            if (!this.$rive) {
                this.loadAnimation();
                // console.log('Load Animation', this.$canvas);
            } else {
                this.cleanUp();
                // console.log('CleanUp Animation', this.$rive);
            }
        }

        window.addEventListener('resize', () => {
            this.handleResize();
        });

        window.addEventListener('beforeunload', () => {
            this.cleanUp();
        });
    }

    loadAnimation() {
        if (this.$rive) return;

        this.$rive = new rive.Rive({
            src: this.getAnimationFilePath(),
            canvas: this.$canvas,
            autoplay: true,
            onLoad: () => {
                this.$rive.resizeDrawingSurfaceToCanvas();
                this.$canvas.classList.toggle('visible', true);
            }
        });

        if (this.$rive) {
            this.$rive.resizeDrawingSurfaceToCanvas();
        }
    }

    cleanUp() {
        if (!this.$rive) return;

        try {
            this.$rive.cleanup();
            this.$rive.cleanupInstances();
            this.$rive = null;
            if (this.$canvas) {
                this.$canvas.classList.toggle('visible', false);
            }
        } catch (e) {
            return false;
        }
    }

    determineBreakpointChange() {
        const shouldBeMobileAnimation = window.innerWidth < this.$breakpoint;

        if (this.$isDesktopAnimation && shouldBeMobileAnimation) {
            this.$isDesktopAnimation = false;
            return true;
        } else if (!this.$isDesktopAnimation && !shouldBeMobileAnimation) {
            this.$isDesktopAnimation = true;
            return true;
        }

        return false;
    }

    getAnimationFilePath() {
        let path;

        if (this.$animationMobile && this.$breakpoint) {
            path = this.$isDesktopAnimation
                ? this.$animationDesktop
                : this.$animationMobile;
        } else {
            path = this.$animationDesktop;
        }

        return path.endsWith('.riv') ? path : null;
    }

    handleResize() {
        if (!this.$canvas) return;

        if (this.$animationMobile && this.$breakpoint) {
            const bpChanged = this.determineBreakpointChange();
            const path = this.getAnimationFilePath();

            if (path && bpChanged) {
                this.cleanUp();

                this.$rive = new rive.Rive({
                    src: path,
                    canvas: this.$canvas,
                    autoplay: true,
                    onLoad: () => {
                        this.$rive.resizeDrawingSurfaceToCanvas();
                    }
                });
            }
        } else {
            try {
                this.$rive.resizeDrawingSurfaceToCanvas();
            } catch (e) {
                return false;
            }
        }
    }
}

export { RiveAnimation };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;
    if ($context) {
        const $animations = $context.querySelectorAll(
            '[data-rive-canvas="root"]'
        );
        if ($animations.length > 0) {
            for (let i = 0; i < $animations.length; i++) {
                new RiveAnimation($animations[i]);
            }
        }
    }
});
